import React from "react";
import styled from "styled-components";
import BlackLogo from "../../assets/BlackLogo.svg";
import BuildingsIcon from "../../assets/NavIcons/BuildingsIcon.svg";
import { useNavigate } from "react-router-dom";
import Exit from "../../assets/NavIcons/Exit.svg";
import { signOutUser } from "../../store/firebase";
import HomeIcon from "../../assets/NavIcons/HomeIcon.svg";
import LoginWrapper from "../../store/LoginWrapper";
import Shapes from "../../assets/NavIcons/Shapes.svg";
import Lock from "../../assets/NavIcons/Lock.svg";
import Settings from "../../assets/NavIcons/Settings.svg";
import Account from "../../assets/NavIcons/Account.svg";
import { useAuth } from "../../store/AuthContext";
import { useGlobalData } from "../../store/GlobalDataProvider";
import "@fontsource/plus-jakarta-sans";

const Container = styled.div`
  height: 100vh;
  width: 220px;
  min-width: 220px;
  position: sticky;
  top: 0;
  bottom: 0;
  border-right: 1px solid #ebebeb;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: "Plus Jakarta Sans";
`;

const NavBarContainer = styled.div`
  width: 180px;
  padding: 20px;
  //   height: 100vh;
  // background-color: #f9f9fb;
  // background-color: #eff6ff;
  background-color: #ffffff;
  // background-color: #fdfdfd;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 0;
  bottom: 0;
`;

const Logo = styled.img`
  width: 115px;
  margin-top: 20px;
  margin-left: 20px;
`;

const NavLinkContainer = styled.div`
  width: 180px;
  margin-top: 30px;
`;

const NavLinkIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-top: 10px;
  margin-left: 15px;
  transition: 0.3s;
  ${(props) =>
    props.selected
      ? "filter: brightness(0) saturate(100%) invert(31%) sepia(23%) saturate(5347%) hue-rotate(218deg) brightness(103%) contrast(113%)"
      : ""}
`;

const NavLinkText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => (props.selected ? "#3958ff" : "#484848")};
  margin-left: 10px;
  margin-top: 9px;
  transition: 0.3s;
  &:hover {
    color: #3958ff;
  }
`;

const NavLink = styled.div`
  margin-bottom: 4px;
  display: flex;
  width: 100%;
  height: 38px;
  transition: 0.3s;
  border-radius: 7px;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? "#f3f6ff" : "#ffffff")};
  border: ${(props) =>
    props.selected ? "0.7px solid #3958ff" : "1px solid #ffffff"};

  &:hover {
    // background-color: #eff2ff;
    // background-color: #dbeafe;
    background-color: #f3f6ff;
    // border: 1px solid #3958ff;
  }
  &:hover ${NavLinkText} {
    // font-weight: 500;
    color: #3958ff;
  }
  &:hover ${NavLinkIcon} {
    filter: brightness(0) saturate(100%) invert(31%) sepia(23%) saturate(5347%)
      hue-rotate(218deg) brightness(103%) contrast(113%);
  }
`;

const Separator = styled.div`
  height: 1px;
  background-color: #ebebeb;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const NavBar = ({ currentPage }) => {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(currentPage);
  const { logout } = useAuth();
  const { clearUserData } = useGlobalData();

  const handleLogout = () => {
    signOutUser();
    logout();
    clearUserData();
    navigate("/login");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    navigate(`/${newPage}`);
  };

  const openPrivacyPage = () => {
    window.open("/privacy.html", "_blank", "noopener,noreferrer");
  };

  return (
    <LoginWrapper>
      <Container>
        <div>
          <Logo src={BlackLogo} />
          <NavLinkContainer>
            <NavLink
              onClick={(e) => handleChangePage(e, "home")}
              selected={page === "home" ? true : false}
            >
              <NavLinkIcon
                src={HomeIcon}
                selected={page === "home" ? true : false}
              />
              <NavLinkText selected={page === "home" ? true : false}>
                Home
              </NavLinkText>
            </NavLink>
            <NavLink
              onClick={(e) => handleChangePage(e, "requests")}
              selected={page === "requests" ? true : false}
            >
              <NavLinkIcon
                src={BuildingsIcon}
                selected={page === "requests" ? true : false}
              />
              <NavLinkText selected={page === "requests" ? true : false}>
                Energy Bills
              </NavLinkText>
            </NavLink>
            <Separator />
            <NavLink
              onClick={(e) => handleChangePage(e, "api")}
              selected={page === "api" ? true : false}
            >
              <NavLinkIcon
                src={Shapes}
                selected={page === "api" ? true : false}
              />
              <NavLinkText selected={page === "api" ? true : false}>
                API
              </NavLinkText>
            </NavLink>
          </NavLinkContainer>
        </div>
        <div>
          <NavLink
            onClick={(e) => handleChangePage(e, "account")}
            selected={page === "account" ? true : false}
          >
            <NavLinkIcon
              src={Account}
              selected={page === "account" ? true : false}
            />
            <NavLinkText selected={page === "account" ? true : false}>
              My Account
            </NavLinkText>
          </NavLink>
          <NavLink
            onClick={(e) => handleChangePage(e, "settings")}
            selected={page === "settings" ? true : false}
          >
            <NavLinkIcon
              src={Settings}
              selected={page === "settings" ? true : false}
            />
            <NavLinkText selected={page === "settings" ? true : false}>
              Settings
            </NavLinkText>
          </NavLink>
          <NavLink onClick={openPrivacyPage}>
            <NavLinkIcon src={Lock} />
            <NavLinkText>Privacy Policy</NavLinkText>
          </NavLink>
          <NavLink onClick={handleLogout}>
            <NavLinkIcon src={Exit} />
            <NavLinkText>Logout</NavLinkText>
          </NavLink>
        </div>
      </Container>
    </LoginWrapper>
  );
};

export default NavBar;
