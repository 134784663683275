import React, { useState, useEffect } from "react";
import {
  Card,
  CardsContainer,
  CardTitle,
  Light36,
  FlexRow,
  SemiBold16,
  RightArrow,
  FlexRowContainer,
  StaticCheck,
  Error,
} from "./styles";
import { useNavigate } from "react-router-dom";
import BlueRightArrow from "../../../../assets/BlueRightArrow.svg";
import { Regular14 } from "../../../../components/styled/fonts";
import SuccessModal from "../../../../components/modals/SuccessModal/index.js";
import BlueLock from "../../../../assets/BlueLock.svg";
import { useGlobalData } from "../../../../store/GlobalDataProvider.js";
import { formatFirebaseDate } from "../../../../utils/date.js";
import getStripePortalUrl from "./../../apiCalls/getStripePortalUrl.js";
import { useAuth } from "../../../../store/AuthContext.js";
import greenCheck from "../../../../assets/greenCheck.svg";
import { CardsFlex } from "./styles.js";
import VerifyEmailModal from "./components/verifyEmailModal.js";
import { resetPassword } from "../../apiCalls/resetPassword.js";
import { multiFactor } from "firebase/auth";
import AuthModal from "./components/AuthModal.js";

const Overview = () => {
  const { userData } = useGlobalData();
  const { currentUser } = useAuth();
  const [changePasswordEmailSent, setChangePasswordEmailSent] = useState(false);
  const navigate = useNavigate();
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [isMFAEnrolled, setIsMFAEnrolled] = useState(false);
  const [verifyError, setVerifyError] = useState(false);
  const [authModalOpen, setAuthModalOpen] = useState(false);

  useEffect(() => {
    setIsMFAEnrolled(
      multiFactor(currentUser).enrolledFactors &&
        multiFactor(currentUser).enrolledFactors.length > 0
    );
  }, [isEmailVerified]);

  const handleClick2FA = () => {
    if (!isMFAEnrolled) {
      if (isEmailVerified) {
        setAuthModalOpen(true);
      } else {
        setVerifyError(true);
      }
    }
  };

  const handleAuthModalClose = () => {
    setAuthModalOpen(false);
  };

  // Check email verification status on component mount
  useEffect(() => {
    if (currentUser) {
      setIsEmailVerified(currentUser.emailVerified);
    }
  }, [currentUser]);

  const handleOpenEmailModal = () => {
    if (!isEmailVerified) {
      setEmailModalOpen(true);
    }
  };

  const handleCloseEmailModal = () => {
    setEmailModalOpen(false);
  };

  const navigateToPlans = (queryKey = null) => {
    if (queryKey) {
      navigate("/plans?api=true");
    } else {
      navigate("/plans");
    }
  };

  const handleChangePassword = () => {
    setChangePasswordEmailSent(true);
    resetPassword(currentUser);
  };

  const closeChangePasswordSuccessModal = () => {
    setChangePasswordEmailSent(false);
  };

  const freeAccount =
    userData &&
    userData.payments &&
    userData.payments.subscription &&
    userData.payments.subscription.plan === "free";
  const paidAccount =
    userData &&
    userData.payments &&
    userData.payments.subscription &&
    userData.payments.subscription.plan &&
    userData.payments.subscription.plan !== "free";
  const admin = userData && userData.userData && userData.userData.admin;
  const annual =
    userData &&
    userData.payments &&
    userData.payments.subscription &&
    userData.payments.subscription.subscriptionName &&
    userData.payments.subscription.subscriptionName.includes("Annually");
  return (
    <>
      <CardsContainer>
        <CardsFlex>
          {freeAccount && admin && (
            <>
              <Card>
                <div style={{ paddingLeft: "10px" }}>
                  <CardTitle>Your Subscription</CardTitle>
                </div>

                <FlexRowContainer onClick={() => navigateToPlans()}>
                  <FlexRow>
                    <SemiBold16>Unlock Full Access</SemiBold16>
                    <RightArrow src={BlueRightArrow} />
                  </FlexRow>
                </FlexRowContainer>
              </Card>
              <Card>
                <div style={{ paddingLeft: "10px" }}>
                  <CardTitle>API</CardTitle>
                </div>
                <FlexRowContainer onClick={() => navigateToPlans("api")}>
                  <FlexRow>
                    <SemiBold16>Enable API Access</SemiBold16>
                    <RightArrow src={BlueRightArrow} />
                  </FlexRow>
                </FlexRowContainer>
              </Card>
              <Card>
                <div style={{ paddingLeft: "10px" }}>
                  <CardTitle>Your Account</CardTitle>
                </div>
                <FlexRowContainer onClick={() => handleChangePassword()}>
                  <FlexRow>
                    <SemiBold16>Change password</SemiBold16>
                    <RightArrow src={BlueRightArrow} />
                  </FlexRow>
                </FlexRowContainer>
                <FlexRowContainer>
                  <FlexRow onClick={() => handleOpenEmailModal()}>
                    <SemiBold16>Verify Email Address</SemiBold16>
                    {isEmailVerified ? (
                      <StaticCheck src={greenCheck} />
                    ) : (
                      <RightArrow src={BlueRightArrow} />
                    )}
                  </FlexRow>
                </FlexRowContainer>
                <FlexRowContainer>
                  <FlexRow onClick={() => handleClick2FA()}>
                    <SemiBold16>Enable 2 Factor Authentication</SemiBold16>
                    {isMFAEnrolled ? (
                      <StaticCheck src={greenCheck} />
                    ) : (
                      <RightArrow src={BlueRightArrow} />
                    )}
                  </FlexRow>
                </FlexRowContainer>
                {verifyError ? (
                  <Error>Please verify your email above first.</Error>
                ) : (
                  <></>
                )}
              </Card>
            </>
          )}
          {freeAccount && !admin && (
            <Card>
              <div style={{ paddingLeft: "10px" }}>
                <CardTitle>Your Account</CardTitle>
              </div>

              <FlexRowContainer onClick={() => handleChangePassword()}>
                <FlexRow>
                  <SemiBold16>Change password</SemiBold16>
                  <RightArrow src={BlueRightArrow} />
                </FlexRow>
              </FlexRowContainer>
              <FlexRowContainer>
                <FlexRow onClick={() => handleOpenEmailModal()}>
                  <SemiBold16>Verify Email Address</SemiBold16>
                  {isEmailVerified ? (
                    <StaticCheck src={greenCheck} />
                  ) : (
                    <RightArrow src={BlueRightArrow} />
                  )}
                </FlexRow>
              </FlexRowContainer>
              <FlexRowContainer>
                <FlexRow onClick={() => handleClick2FA()}>
                  <SemiBold16>Enable 2 Factor Authentication</SemiBold16>
                  {isMFAEnrolled ? (
                    <StaticCheck src={greenCheck} />
                  ) : (
                    <RightArrow src={BlueRightArrow} />
                  )}
                </FlexRow>
              </FlexRowContainer>
              {verifyError ? (
                <Error>Please verify your email above first.</Error>
              ) : (
                <></>
              )}
            </Card>
          )}

          {paidAccount && admin && (
            <>
              <Card>
                <div style={{ paddingLeft: "10px" }}>
                  <CardTitle>Your Subscription</CardTitle>
                </div>

                {userData &&
                userData.payments.subscription.plan === "payAsYouGo" ? (
                  <>
                    <FlexRowContainer>
                      <FlexRow onClick={() => getStripePortalUrl(currentUser)}>
                        <SemiBold16>Manage Payments</SemiBold16>
                        <RightArrow src={BlueRightArrow} />
                      </FlexRow>
                    </FlexRowContainer>
                    <div style={{ paddingLeft: "25px" }}>
                      <Regular14
                        style={{
                          marginTop: -8,
                          fontSize: 13,
                          color: "#484848",
                          marginBottom: 8,
                        }}
                      >
                        {userData.payments.subscription.subscriptionName}
                      </Regular14>
                    </div>
                    <FlexRowContainer>
                      <FlexRow onClick={() => navigateToPlans()}>
                        <SemiBold16>Upgrade to Subscription</SemiBold16>
                        <RightArrow src={BlueRightArrow} />
                      </FlexRow>
                    </FlexRowContainer>
                  </>
                ) : (
                  <>
                    <FlexRowContainer>
                      <FlexRow onClick={() => getStripePortalUrl(currentUser)}>
                        <SemiBold16>Manage Your Subscription</SemiBold16>
                        <RightArrow src={BlueRightArrow} />
                      </FlexRow>
                    </FlexRowContainer>
                    <div style={{ paddingLeft: "25px" }}>
                      <Regular14
                        style={{
                          marginTop: -8,
                          fontSize: 13,
                          color: "#484848",
                          marginBottom: 8,
                        }}
                      >
                        {userData.payments.subscription.subscriptionName}
                      </Regular14>
                    </div>
                  </>
                )}

                <div
                  style={{
                    paddingLeft: "10px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  {userData &&
                    userData.payments &&
                    userData.payments.subscription && (
                      <>
                        {userData.payments.subscription.plan ===
                        "payAsYouGo" ? (
                          <>
                            <SemiBold16 style={{ marginTop: 16 }}>
                              Current Period Usage
                            </SemiBold16>
                            <Light36 style={{ marginTop: -8 }}>
                              {`${userData.payments.subscription.currentPeriodUsage} `}
                              <span style={{ fontSize: 13 }}>
                                Utility Bills
                              </span>
                            </Light36>
                          </>
                        ) : (
                          <>
                            <SemiBold16>
                              Remaining bills for this period
                            </SemiBold16>
                            <Light36>
                              {`${userData.payments.subscription.remainingBills} `}
                              <span style={{ fontSize: 13 }}>
                                Utility Bills
                              </span>
                            </Light36>
                          </>
                        )}
                      </>
                    )}
                  {userData &&
                    userData.payments &&
                    userData.payments.subscription &&
                    userData.payments.subscription.endDate && (
                      <>
                        <SemiBold16 style={{ marginTop: 8 }}>
                          Current period end date
                        </SemiBold16>
                        <Light36 style={{ marginTop: -8 }}>
                          {formatFirebaseDate(
                            userData.payments.subscription.endDate
                          )}
                        </Light36>
                      </>
                    )}
                </div>
              </Card>
              <Card>
                <div style={{ paddingLeft: "10px" }}>
                  <CardTitle>API</CardTitle>
                </div>
                {userData &&
                  userData.payments &&
                  userData.payments.api &&
                  !userData.payments.api.enabled && (
                    <FlexRowContainer onClick={() => navigateToPlans("api")}>
                      <FlexRow>
                        <SemiBold16>Enable API Usage</SemiBold16>
                        <RightArrow src={BlueRightArrow} />
                      </FlexRow>
                    </FlexRowContainer>
                  )}
                {userData &&
                  userData.payments &&
                  userData.payments.api &&
                  userData.payments.api.enabled && (
                    <FlexRowContainer>
                      <FlexRow>
                        <SemiBold16>API Enabled</SemiBold16>
                        <StaticCheck src={greenCheck} />
                      </FlexRow>
                    </FlexRowContainer>
                  )}
                <FlexRowContainer>
                  <FlexRow>
                    <SemiBold16>View Past Usage</SemiBold16>
                    <RightArrow src={BlueRightArrow} />
                  </FlexRow>
                </FlexRowContainer>
                {userData.payments.api.freeBills > 0 && (
                  <div
                    style={{
                      paddingLeft: "10px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    <SemiBold16>
                      Free API Bills
                    </SemiBold16>
                    <Light36 style={{ marginTop: -8 }}>
                      {`${userData.payments.api.freeBills} `}
                      <span style={{ fontSize: 13 }}>Utility Bills</span>
                    </Light36>
                  </div>
                )}
                {userData &&
                  userData.payments &&
                  userData.payments.api &&
                  userData.payments.api.endDate && (
                    <div
                      style={{
                        paddingLeft: "10px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                        marginTop: 16,
                      }}
                    >
                      <SemiBold16>Next Payment for API use</SemiBold16>
                      <Light36 style={{ marginTop: -8 }}>
                        {formatFirebaseDate(userData.payments.api.endDate)}
                      </Light36>
                    </div>
                  )}
              </Card>
              <Card>
                <div style={{ paddingLeft: "10px" }}>
                  <CardTitle>Your Account</CardTitle>
                </div>
                <FlexRowContainer onClick={() => handleChangePassword()}>
                  <FlexRow>
                    <SemiBold16>Change password</SemiBold16>
                    <RightArrow src={BlueRightArrow} />
                  </FlexRow>
                </FlexRowContainer>
                <FlexRowContainer>
                  <FlexRow onClick={() => getStripePortalUrl(currentUser)}>
                    <SemiBold16>Manage Payment Methods</SemiBold16>
                    <RightArrow src={BlueRightArrow} />
                  </FlexRow>
                </FlexRowContainer>
                <FlexRowContainer>
                  <FlexRow onClick={() => handleOpenEmailModal()}>
                    <SemiBold16>Verify Email Address</SemiBold16>
                    {isEmailVerified ? (
                      <StaticCheck src={greenCheck} />
                    ) : (
                      <RightArrow src={BlueRightArrow} />
                    )}
                  </FlexRow>
                </FlexRowContainer>
                <FlexRowContainer>
                  <FlexRow onClick={() => handleClick2FA()}>
                    <SemiBold16>Enable 2 Factor Authentication</SemiBold16>
                    {isMFAEnrolled ? (
                      <StaticCheck src={greenCheck} />
                    ) : (
                      <RightArrow src={BlueRightArrow} />
                    )}
                  </FlexRow>
                </FlexRowContainer>
                {verifyError ? (
                  <Error>Please verify your email above first.</Error>
                ) : (
                  <></>
                )}
              </Card>
            </>
          )}
          {paidAccount && !admin && (
            <>
              <Card>
                <CardTitle>Your Subcription</CardTitle>
                <SemiBold16>Remaining bills for this period</SemiBold16>
                <Light36>53 utility bills</Light36>
                <SemiBold16>Current period end date</SemiBold16>
                <Light36>9/30/2024</Light36>
              </Card>
              <Card>
                <CardTitle>API</CardTitle>
                <SemiBold16>Current Period API Usage</SemiBold16>
                <Light36>53 bills</Light36>
              </Card>
              <Card style={{ marginBottom: 120 }}>
                <div style={{ paddingLeft: "10px" }}>
                  <CardTitle>Your Account</CardTitle>
                </div>
                <FlexRowContainer onClick={() => handleChangePassword()}>
                  <FlexRow>
                    <SemiBold16>Change password</SemiBold16>
                    <RightArrow src={BlueRightArrow} />
                  </FlexRow>
                </FlexRowContainer>
                <FlexRowContainer>
                  <FlexRow onClick={() => handleOpenEmailModal()}>
                    <SemiBold16>Verify Email Address</SemiBold16>
                    {isEmailVerified ? (
                      <StaticCheck src={greenCheck} />
                    ) : (
                      <RightArrow src={BlueRightArrow} />
                    )}
                  </FlexRow>
                </FlexRowContainer>
                <FlexRowContainer>
                  <FlexRow onClick={() => handleClick2FA()}>
                    <SemiBold16>Enable 2 Factor Authentication</SemiBold16>
                    {isMFAEnrolled ? (
                      <StaticCheck src={greenCheck} />
                    ) : (
                      <RightArrow src={BlueRightArrow} />
                    )}
                  </FlexRow>
                </FlexRowContainer>
                {verifyError ? (
                  <Error>Please verify your email above first.</Error>
                ) : (
                  <></>
                )}
              </Card>
            </>
          )}
        </CardsFlex>
      </CardsContainer>

      {changePasswordEmailSent && (
        <SuccessModal
          open={changePasswordEmailSent}
          handleClose={closeChangePasswordSuccessModal}
          text={"A reset password link has been sent to your email."}
          icon={BlueLock}
        />
      )}
      <div id="recaptcha-container"></div>

      <VerifyEmailModal
        isEmailVerified={isEmailVerified}
        open={emailModalOpen}
        handleClose={handleCloseEmailModal}
      />

      <AuthModal open={authModalOpen} handleClose={handleAuthModalClose} />
    </>
  );
};

export default Overview;
