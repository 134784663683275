import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Card from "../../../../components/cards/Card";
import { formatDollars, formatWithUnit, capitalizeFirstLetterOfEachWord, formatDollarsPerUnit } from "../../../../utils/formatNumbers";
import UsageChart from "../../components/UsageChart";
import { IconButton, Select, Tooltip } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import {averageByKey, calculateSumTotal, getMaxByKey, checkKey, createAccountsArray, getServiceClassArray, getServiceClassData, getServiceClassUnit, calculateAverageBlendedRate, hasNonNullOrZeroKey} from './utils'
import { Toggle } from "../../../../components/buttons/Toggle";
import info from "../../../../assets/info.svg"
import BarChart from "../../components/barChart";

const OverviewContainer = styled.div`
    width: 100%;
`;

const Title = styled.div`
    font-size: 18px;
    margin-bottom: 24px;
`

const OverviewRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`

const OverviewHeader = styled.div`
    width: 240px;
    color: #7A7A7A;
    font-size: 13px;
`

const OverviewValue = styled.div`
    width: 240px;
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis; 
    white-space: nowrap; 
`

const DataPoint = styled.div`
    width: 240px;
`

const DataPointTitle = styled.div`
    color: #7A7A7A;
    font-size: 13px;
    margin-bottom: 8px;
`

const DataPointValue = styled.div`
    font-weight: 400;
    font-size: 16px;
`

const Flex = styled.div`
    display: flex;
`

const Icon = styled.img`
    width: 12px;
    height: 12px;
    opacity: .5;
`

function tarrifCheck(data) {
    for (let act in data) {
        for (let sc in data[act]) {
            if (data[act][sc].overview.tariff) { return true }
        }
    }
    return false
}

const MeterDataOverview = ({solarProductivity, data, preprocessedData, preprocessedMonthlyData }) => {

    const [overviewServiceClass, setOverviewServiceClass] = useState(null)
    const [serviceClassArr, setServiceClassArr] = useState([])
    const [overviewServiceClassData, setOverviewServiceClassData] = useState([])
    const [serviceClassUnit, setServiceClassUnit] = useState(null)
    const [monthly, setMonthly] = useState(false)
    const [demand, setDemand] = useState(false)

    const handleChangeMonthly = (e) => {
        setMonthly(e)
        if (e) { setDemand(false) }
    }

    const handleChangeDemand = (e) => {
        setDemand(e)
    }

    const handleServiceClassChange = (e) => {
        setOverviewServiceClass(e.target.value)
        setDemand(false)
        setMonthly(false)
    }

    useEffect(() => {
        var generatedServiceClassArr = getServiceClassArray(data)
        setServiceClassArr(generatedServiceClassArr)
        setOverviewServiceClass(generatedServiceClassArr.includes('electric') ? 'electric' : generatedServiceClassArr[0])
    }, []);

    useEffect(() => {
        var generatedServiceClassData = getServiceClassData(data, overviewServiceClass)
        setOverviewServiceClassData(generatedServiceClassData)
        var unit = getServiceClassUnit(data, overviewServiceClass)
        setServiceClassUnit(unit)
    }, [overviewServiceClass, data]);

    function returnOverviewData(account) {
        if (!account) { return (<></>) }
        return (
            <>
            <div style={{marginTop: '16px'}}/>
            <OverviewRow>
                <OverviewValue key={0} style={{width: '100px'}}>{capitalizeFirstLetterOfEachWord(data[account[0]][account[1]].overview.serviceClass)}</OverviewValue>
                <OverviewValue key={1} style={{width: tarrifCheck(data) ? '100px' : '200px'}}>{data[account[0]][account[1]].overview.utility}</OverviewValue>
                <OverviewValue key={2} >{data[account[0]][account[1]].overview.serviceAddress}</OverviewValue>
                <OverviewValue key={3} style={{display: tarrifCheck(data) ? '' : 'none'}}>{data[account[0]][account[1]].overview.tariff}</OverviewValue>
                <OverviewValue key={4} style={{width: '100px'}}>{data[account[0]][account[1]].data.length}</OverviewValue>
            </OverviewRow>
            </>
        )
    }

    return (
        <div style={{width: 900, margin:"0 auto"}}>
            <div style={{marginTop: '24px'}}/>
            <Card>
                <OverviewContainer>
                    <Title>All Accounts</Title>
                    <OverviewRow>
                        <OverviewHeader key={0} style={{width: '100px'}}>Service Class</OverviewHeader>
                        <OverviewHeader key={1} style={{width: tarrifCheck(data) ? '100px' : '200px'}}>Utility</OverviewHeader>
                        <OverviewHeader key={2} >Service Address</OverviewHeader>
                        <OverviewHeader key={3} style={{display: tarrifCheck(data) ? '' : 'none'}}>Tariff</OverviewHeader>
                        <OverviewHeader key={4} style={{width: '100px'}}>Bills Count</OverviewHeader>
                    </OverviewRow>

                    {(createAccountsArray(data)).map((account) => {
                        return returnOverviewData(account)
                    })}

                </OverviewContainer>
            </Card>
            <div style={{marginTop: '16px'}}/>
            <Card>
                <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
                    <FormControl sx={{ width: 200 }} size="small">
                        <Select
                            disabled={(serviceClassArr.length < 2)}
                            value={overviewServiceClass === null ? '' : overviewServiceClass}
                            onChange={handleServiceClassChange}
                        >                    
                            {serviceClassArr.map((serviceClass, index) => {
                                return (<MenuItem key={index} value={serviceClass}>{capitalizeFirstLetterOfEachWord(serviceClass)}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                    
                { overviewServiceClassData.length > 1 ?
                            <div style={{display: "flex", gap: 16}}>
                                {overviewServiceClass === 'electric' && !monthly ? <Toggle options={['Usage', 'Demand']} handleToggle={handleChangeDemand} value={demand} /> : <></>}
                                <Toggle options={['Bills', 'Monthly']} handleToggle={handleChangeMonthly} value={monthly} />
                            </div> 
                        : <></>}
                    </div>

                { overviewServiceClassData.length > 1 ? 
                    <>
                        <div style={{marginBottom: -8, display: (monthly ? 'none' : '')}}>
                            <UsageChart data={data} preprocessedData={preprocessedData} serviceClass={overviewServiceClass} unit={serviceClassUnit} demand={demand}/> 
                        </div>
                        <div style={{display: (monthly ? '' : 'none')}}>
                            <BarChart monthlyData={preprocessedMonthlyData} unit={serviceClassUnit} serviceClass={overviewServiceClass} /> 
                        </div>
                    </>
                : <></> }

                <div style={{marginTop: '16px'}}/>
                <Title>Analysis</Title>
                <OverviewRow>
                    <DataPoint>
                        <DataPointTitle>Total Usage</DataPointTitle>
                        <DataPointValue>{formatWithUnit(calculateSumTotal(overviewServiceClassData, 'usage'), serviceClassUnit)}</DataPointValue>
                    </DataPoint>
                    <DataPoint>
                        <DataPointTitle>Total Charges</DataPointTitle>
                        <DataPointValue>{formatDollars(calculateSumTotal(overviewServiceClassData, 'totalCharges'))}</DataPointValue>
                    </DataPoint>
                    <DataPoint>
                        <Flex>
                            <DataPointTitle>Cost per Unit</DataPointTitle>
                            <div style ={{marginTop: -6, marginLeft: -1}}>
                                <Tooltip title={<div style={{fontSize: 13, lineHeight: 1.5}}>Total charges divided by total usage.</div>}>
                                    <IconButton>
                                        <Icon src={info} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </Flex>
                        <DataPointValue>{formatDollarsPerUnit(calculateSumTotal(overviewServiceClassData, 'totalCharges')/calculateSumTotal(overviewServiceClassData, 'usage'), serviceClassUnit)}</DataPointValue>
                    </DataPoint>
                </OverviewRow>

                { checkKey(overviewServiceClassData, 'demand') && hasNonNullOrZeroKey(overviewServiceClassData, 'demand') && getMaxByKey(overviewServiceClassData, 'demand') > 0 ?
                    <OverviewRow style={{marginTop: '24px'}}>
                        <DataPoint>
                            <DataPointTitle>Peak Demand</DataPointTitle>
                            <DataPointValue>{formatWithUnit(getMaxByKey(overviewServiceClassData, 'demand'), 'kW')}</DataPointValue>
                        </DataPoint>
                        <DataPoint>
                            <DataPointTitle>Average Demand</DataPointTitle>
                            <DataPointValue>{formatWithUnit(Math.round(averageByKey(overviewServiceClassData, 'demand') * 10) / 10, 'kW')}</DataPointValue>
                        </DataPoint>
                        <DataPoint>
                            <Flex>
                                <DataPointTitle>Demand Charges per kW</DataPointTitle>
                                <div style ={{marginTop: -6, marginLeft: -1}}>
                                    <Tooltip title={<div style={{fontSize: 13, lineHeight: 1.5}}>Total demand charges divided by total peak demand. Does not include taxes.</div>}>
                                        <IconButton>
                                            <Icon src={info} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </Flex>
                            <DataPointValue>{formatDollarsPerUnit(calculateSumTotal(overviewServiceClassData, 'totalDemandCharges')/calculateSumTotal(overviewServiceClassData, 'demand'), 'kW')}</DataPointValue>
                        </DataPoint>
                    </OverviewRow> : <></>
                }

                { (checkKey(overviewServiceClassData, 'supplyTaxes') || checkKey(overviewServiceClassData, 'supplySalesTax')) && (overviewServiceClass === 'electric' || overviewServiceClass === 'natural gas') ?
                    <OverviewRow style={{marginTop: '24px'}}>
                        <DataPoint>
                            <Flex>
                                <DataPointTitle>Average kWh Rate</DataPointTitle>
                                <div style ={{marginTop: -6, marginLeft: -1}}>
                                    <Tooltip title={<div style={{fontSize: 13, lineHeight: 1.5}}>All per kWh line items from each bill divided by total kWhs. Includes sales tax.</div>}>
                                        <IconButton>
                                            <Icon src={info} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </Flex>
                            <DataPointValue>{calculateAverageBlendedRate(overviewServiceClassData, solarProductivity, false)}</DataPointValue>
                        </DataPoint>
                        <DataPoint>
                            <Flex>
                                <DataPointTitle>Solar Avoided Cost</DataPointTitle>
                                <div style ={{marginTop: -6, marginLeft: -1}}>
                                    <Tooltip title={<div style={{fontSize: 13, lineHeight: 1.5}}>The average kWh rate weighted by solar productivity for each calendar month. Solar productivity can be viewed and adjusting in the settings page.</div>}>
                                        <IconButton>
                                            <Icon src={info} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </Flex>
                            <DataPointValue>{calculateAverageBlendedRate(overviewServiceClassData, solarProductivity, true)}</DataPointValue>
                        </DataPoint>
                        <DataPoint>
                        </DataPoint>
                    </OverviewRow> : <></>
                }
            </Card>
            { overviewServiceClassData.length > 1 ? <div style={{marginTop: '100px'}}/> : <></> }

        </div>
    );
};

export default MeterDataOverview;