import React, { useState } from "react";
import {
  Container,
  PageContainer,
  HeaderTitle,
  FlexContainer,
} from "./styles";
import Page from "../../components/page/index";
import LoginWrapper from "../../store/LoginWrapper";
import Tabs from "../../components/Tabs";
import Seats from "./pages/seats/seats.js";
import { useGlobalData } from "../../store/GlobalDataProvider.js";
import Overview from "./pages/overview/overview.js";
import Usage from "./pages/usage/usage.js";

const Account = () => {
  const { userData } = useGlobalData();
  const [currentTab, setCurrentTab] = useState(0);
  const handleTabChange = (target) => {
    setCurrentTab(target);
  };

  const admin = userData && userData.userData && userData.userData.admin;
  
  return (
    <LoginWrapper>
      <Page currentPage={'account'}>
            <Container>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <HeaderTitle>Manage Your Account</HeaderTitle>
              </div>
              {admin && (
                <div style={{ marginTop: 16 }}>
                  <Tabs
                    tabs={["Overview", "Seats", "Usage"]}
                    setTabInParent={handleTabChange}
                  />
                </div>
              )}
            </Container>
            {currentTab === 0 && <Overview />}
            {currentTab === 1 && (
              <Seats
                seats={[
                  { admin: true, email: "max@buildupward.com" },
                  { admin: false, email: "arvind.ramgopal@gmail.com" },
                ]}
              />
            )}
            {currentTab === 2 && <Usage />}

      </Page>
    </LoginWrapper>
  );
};

export default Account;
